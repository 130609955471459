import variant from 'apps/acp/variants/www.skylightallaccess.com';
import { createBehaviorSubject } from 'packages/behavior-subject';

import { startGateway } from 'apps/acp/gateway';
import { mapVariantToAcpEnv } from 'apps/acp/gateway/map-variant';

const mappedVariantEnv = mapVariantToAcpEnv(variant);


startGateway(mappedVariantEnv);
